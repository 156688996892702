<template>
  <AllerSlugHead :page-title="pageTitle" :meta-tags="metaTags" :links="headLinks" />
  <div id="slug">
    <AdWrapper :ad="{ slotId: 'dsk_top_ad' }" class="hidden lg:block" />
    <AdWrapper :ad="{ slotId: 'mob_top_ad' }" class="lg:hidden" />
    <div class="bg-grey-200 py-10 min-h-[320px] lg:min-h-[180px] flex items-center justify-center" v-if="!native">
      <AdWrapper :ad="{ slotId: route?.path?.indexOf('/det-fraekke') > -1 ? 'cncpt-dsk_lb1_DF' : 'dsk_responsive_1', imediateRender: true }" allow-naughty-ad class="hidden lg:flex" />
      <AdWrapper :ad="{ slotId: route?.path?.indexOf('/det-fraekke') > -1 ? 'cncpt-mob1_DF' : 'mob_responsive_1', imediateRender: true }" allow-naughty-ad class="flex lg:hidden" />
    </div>
    <div id="adnm-content">
      <LazyNewsRibbonContainer v-if="isValidRibbonPageType && !!newsRibbonCategoryId" :category-id="newsRibbonCategoryId" :article-url="isNewsRibbonCategoryPage ? null : data.data?.attributes?.url" />
      <LazyPageTypeArticle v-if="pageType === 'article'" />
      <LazyPageTypeBasicPage v-else-if="pageType === 'page'" />
      <LazyPageTypeAdvertorialArticle v-else-if="pageType === 'advertorialArticle'" />
      <LazyPageTypeCategory v-else-if="pageType === 'category'" />
      <LazyPageTypeSubCategory v-else-if="pageType === 'subCategory'" />
      <LazyPageTypeNaughtyCategory v-else-if="pageType === 'naughtyCategory'" />
      <LazyPageTypeTag v-else-if="pageType === 'tag'" />
      <LazyPageTypePerson v-else-if="pageType === 'person'" />
      <div v-if="token" class="bg-blue-400 z-60 fixed bottom-0 right-0 py-10 px-15 text-white text-20 rounded-tl-lg">Preview</div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Slug',
  template: '<div></div>' // This is only used when throw createError is called
}
</script>

<script setup lang="ts">
definePageMeta({
  validate: async (route) => {
    // We do not want the slug file to deal with anything that has a file extension (e.g. old drupal 7 image paths).
    // By removing file paths we also save the request to the drupal backend.
    return !/^.*\.(jpg|jpeg|png|gif|ico|json|rss|xml|svg|js|css|mjs|woff|woff2|pdf)$/.test(route.fullPath);
  }
});

// Prepare slug from nuxt-base.
const { redirect, token, data, primaryCategory, native, videoAd, pageTitle, metaTags, headLinks, gtmSlugPush } = await useAllerSlug();

// Direct parent category
const { directParent } = useAllerIncludedCategoryTree(data.value);

// If we got a redirect back from the backend.
if (redirect) {
  navigateTo(data.value.data.attributes.to, { replace: true, redirectCode: 301, external: data.value.data.attributes.to.startsWith('http') });
}

// Set Ad Tech page-level targeting.
onBeforeMount(() => {
  useAllerSlugPageAdTargeting(data.value, pageType.value, primaryCategory);
});

const route = useRoute();

// Provide variables for child-components.
provide('apiData', data.value);
provide('videoAd', videoAd);

// Compute the page type in order to render the correct page type component.
const pageType = computed((): string => {
  switch (data.value.data?.type) {
    case 'category':
      if (data.value.data.attributes.topParent) {
        return 'subCategory';
      }
      if (data.value.data.id == "16") {
        return 'naughtyCategory';
      }
      return 'category';
    case 'article':
      return 'article';
    case 'advertorial':
      return 'advertorialArticle';
    case 'tag':
      return 'tag';
    case 'page':
      return 'page';
    case 'aptoma':
      return 'aptoma';
    case 'person':
      return 'person';
  }
  return '';
});

// Valid page types for the news ribbon.
const isValidRibbonPageType = computed((): boolean => ['article', 'page', 'advertorialArticle', 'category', 'subCategory', 'naughtyCategory'].includes(pageType.value));
const newsRibbonCategoryId = directParent?.id || data.value.data.id;
const isNewsRibbonCategoryPage = ['category', 'subCategory'].includes(pageType.value);

// Set native state based on page data.
useState('native').value = native;

// Trigger the push to dataLayer.
gtmSlugPush();
</script>
